define([
    'santa-components',
    'lodash',
    'svgShape/components/svgShape',
    'componentsCore'
], function (santaComponents, _, svgShape, componentsCore) {
    'use strict';

    const popupCloseIconButton = _.cloneDeep(svgShape); // eslint-disable-line santa/no-module-state

    function onClick() {
        this.props.closePopupPage();
    }

    popupCloseIconButton.propTypes = _.defaults({
        closePopupPage: santaComponents.santaTypesDefinitions.popupPage.close
    }, svgShape.propTypes);

    popupCloseIconButton.displayName = 'PopupCloseIconButton';

    popupCloseIconButton.getSkinProperties = function getSkinProperties() {
        const refData = svgShape.getSkinProperties.apply(this, arguments);
        const a11yTitle = 'Back to site';

        refData[''].onClick = onClick.bind(this);
        refData[''].style = _.assign(refData[''].style, {cursor: 'pointer'});
        refData[''].role = 'button';
        refData[''].onKeyDown = componentsCore.utils.accessibility.keyboardInteractions.activateBySpaceOrEnterButton;
        refData[''].tabIndex = '0';
        refData[''].title = a11yTitle;
        refData['']['aria-label'] = a11yTitle;

        return refData;
    };


    return popupCloseIconButton;
});
