define([
    'lodash',
    'componentsCore',
    'coreUtils'
], function (_, componentsCore, coreUtils) {
    'use strict';

    function checkRequirements(siteData, compInfo) {
        return _.compact([
            coreUtils.svg.createSvgFetchRequest(
                siteData.serviceTopology.mediaRootUrl,
                siteData,
                _.get(compInfo, ['data', 'svgId'], _.get(compInfo, ['style', 'style', 'properties', 'svgId']))
            )
        ]);
    }

    componentsCore.dataRequirementsCheckerRegistrar.registerCheckerForCompType('wysiwyg.viewer.components.VectorImage', checkRequirements);
    componentsCore.dataRequirementsCheckerRegistrar.registerCheckerForCompType('wysiwyg.viewer.components.BackToTopButton', checkRequirements);

    return {
        checkRequirements
    };
});
