define([
    'lodash',
    'santa-components',
    'componentsCore',
    'svgShape/components/vectorImage'
], function (
    _,
    santaComponents,
    componentsCore,
    vectorImage
) {
    'use strict';

    const BACK_TO_TOP_IN_ACTION_NAME = 'backToTopIn';
    const BACK_TO_TOP_DISTANCE = 512;
    const BACK_TO_TOP_HIDE_DELAY = 3000;
    const BI_EVENTS = {
        CLICK_BACK_TO_TOP: {
            eventId: 330,
            adapter: 'ugc-viewer'
        }
    };

    const isEditMode = componentViewMode => componentViewMode === 'editor';

    const getInAnimation = behaviors => _.find(behaviors, {action: BACK_TO_TOP_IN_ACTION_NAME});

    const backToTopButtonMixin = {
        propTypes: {
            getTranslatedAriaLabel: santaComponents.santaTypesDefinitions.Accessibility.getTranslatedAriaLabel,
            behaviors: santaComponents.santaTypesDefinitions.Component.compBehaviors.isRequired,
            windowScrollEventAspect: santaComponents.santaTypesDefinitions.SiteAspects.windowScrollEvent.isRequired,
            isZoomed: santaComponents.santaTypesDefinitions.mobile.isZoomed.isRequired,
            isMobileDevice: santaComponents.santaTypesDefinitions.Device.isMobileDevice.isRequired,
            windowResizeEvent: santaComponents.santaTypesDefinitions.SiteAspects.windowResizeEvent,
            componentViewMode: santaComponents.santaTypesDefinitions.RenderFlags.componentViewMode,
            reportBI: santaComponents.santaTypesDefinitions.reportBI.isRequired,
            ios: santaComponents.santaTypesDefinitions.BrowserFlags.ios.isRequired
        },

        getInitialState() {
            const {componentViewMode, getTranslatedAriaLabel, windowResizeEvent, windowScrollEventAspect} = this.props;

            this.linkAriaLabel = getTranslatedAriaLabel('ariaLabels', 'Mobile_BackToTop_AriaLabel_Button', 'Back to top');

            const isVisibleOnLoad = isEditMode(componentViewMode);
            if (!isVisibleOnLoad) {
                windowScrollEventAspect.registerToScroll(this);
                windowResizeEvent.registerToOrientationChange(this);
            }

            return {
                isVisible: isVisibleOnLoad
            };
        },

        componentDidMount() {
            this.debouncedHideButton = _.debounce(() => this.setState({isVisible: false}), BACK_TO_TOP_HIDE_DELAY);
        },

        componentWillReceiveProps(nextProps) {
            const switchToPreview = isEditMode(this.props.componentViewMode) && !isEditMode(nextProps.componentViewMode);
            const switchToEditor = !isEditMode(this.props.componentViewMode) && isEditMode(nextProps.componentViewMode);

            if (switchToPreview || switchToEditor) {
                this.immediate = true;
                this.debouncedHideButton.cancel();
            }

            if (switchToPreview) {
                this.setState({isVisible: false});
                this.props.windowScrollEventAspect.registerToScroll(this);
            } else if (switchToEditor) {
                this.setState({isVisible: true});
                this.props.windowScrollEventAspect.unregisterToScroll(this);
            }
        },

        componentDidUpdate(prevProps, prevState) {
            if (prevState.isVisible && !this.state.isVisible) {
                this.hideButton();
            } else if (!prevState.isVisible && this.state.isVisible) {
                this.showButton();
            }
            this.immediate = false;
        },

        componentWillUnmount() {
            this.props.windowScrollEventAspect.unregisterToScroll(this);
        },

        onOrientationChange() {
            this.forceUpdate();
        },

        showButton() {
            const inAnimation = getInAnimation(this.props.behaviors);

            if (inAnimation && !this.immediate) {
                this.animate(this, inAnimation.name, inAnimation.duration, inAnimation.delay, inAnimation.params);
            } else {
                this.animate(this, 'FadeIn', 0, 0);
            }
        },

        hideButton() {
            const inAnimation = getInAnimation(this.props.behaviors);

            if (inAnimation && !this.immediate) {
                this.animate(this, 'FadeOut', 0.5, 0);
            } else {
                this.animate(this, 'FadeOut', 0, 0);
            }
        },

        onScroll(position) {
            const isMobileDeviceAndZoomed = this.props.isZoomed() && this.props.isMobileDevice;
            const shouldShowButton = !isMobileDeviceAndZoomed && position.y > BACK_TO_TOP_DISTANCE;

            if (shouldShowButton) {
                if (!this.state.isVisible) {
                    this.setState({isVisible: true});
                }
                this.debouncedHideButton();
            }
        },

        transformRefData(refData) {
            if (!this.state.isVisible) {
                _.set(refData[''], ['style', 'opacity'], 0);
            }
            refData[''].onClick = () => this.props.reportBI(BI_EVENTS.CLICK_BACK_TO_TOP);
            if (this.props.ios() === true) {
                _.set(refData[''], ['style', 'touchAction'], 'manipulation');
            }
            refData.link['aria-label'] = this.linkAriaLabel;
        }
    };

    const backToTopButton = _.defaults({
        displayName: 'backToTopButton',
        mixins: vectorImage.mixins.concat([backToTopButtonMixin, santaComponents.mixins.animationsMixin])
    }, vectorImage);

    componentsCore.compRegistrar.register('wysiwyg.viewer.components.BackToTopButton', backToTopButton);

    return backToTopButton;
});
