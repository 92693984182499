define(['componentsCore', 'lodash', 'coreUtils'], function (componentsCore, _, coreUtils) {
    'use strict';

    function requirementChecker(mediaRootUrl, siteData, skinNames) {
        return _(skinNames)
            .without('skins.viewer.svgshape.SvgShapeDefaultSkin')
            .map(_.partial(coreUtils.svg.createSvgFetchRequest, mediaRootUrl, siteData, _))
            .compact()
            .value();
    }

    function checkRequirements(siteData, compInfo) {
        return requirementChecker(siteData.serviceTopology.mediaRootUrl, siteData, compInfo.skins);
    }

    componentsCore.dataRequirementsCheckerRegistrar.registerCheckerForCompType('wysiwyg.viewer.components.svgshape.SvgShape', checkRequirements);
    componentsCore.dataRequirementsCheckerRegistrar.registerCheckerForCompType('wysiwyg.viewer.components.PopupCloseIconButton', checkRequirements);

    //return object is for testing only
    return {
        requirementChecker,
        DEFAULT_SHAPE: coreUtils.svgUtils.EMPTY_SHAPE,
        SVG_ROOT: coreUtils.svgUtils.SVG_ROOT
    };
});
