define([
    'svgShape/components/popupCloseIconButton',
    'svgShape/components/svgShape',
    'svgShape/components/vectorImage',
    'svgShape/components/backToTopButton',
    'skins',
    'svgShape/skins/skins.json',
    'svgShape/util/svgShapeDataRequirementsChecker',
    'svgShape/util/vectorImageDataRequirementsChecker'
], function (
    popupCloseIconButton,
    svgShape,
    vectorImage,
    backToTopButton,
    skinsPackage,
    skinsJson
) {
    'use strict';

    skinsPackage.skinsMap.addBatch(skinsJson);

    return {
        popupCloseIconButton,
        svgShape,
        vectorImage,
        backToTopButton
    };
});
